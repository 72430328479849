import React from 'react';
import CarCard from '../../CardsWrapper/CarCard';
import './index.css';

export default function FeaturedVehicles() {
  const FeaturedVehiclesData = [
    {
      img: 'car_1',
      title: 'Standard SUV',
      similarTo: 'Ford Edge or Similar',
      type: 'Automatic',
      noOfPeople: 5,
      noOfBags: 5,
      dailyRate: 45.0,
      totalCost: 698.0,
      cardPath: '#',
      selectPath: '#',
    },
    {
      img: 'car_2',
      title: 'Standard SUV',
      similarTo: 'Ford Edge or Similar',
      type: 'Automatic',
      noOfPeople: 5,
      noOfBags: 5,
      dailyRate: 45.0,
      totalCost: 698.0,
      cardPath: '#',
      selectPath: '#',
    },
    {
      img: 'car_3',
      title: 'Intermediate',
      similarTo: 'Nissan Versa or Similar',
      type: 'Automatic',
      noOfPeople: 5,
      noOfBags: 5,
      dailyRate: 45.0,
      totalCost: 698.0,
      cardPath: '#',
      selectPath: '#',
    },
  ];

  return (
    <div className='featured_vehicles'>
      <div className='heading'>Featured Vehicles</div>
      <div className='featured_card_wrapper'>
        {FeaturedVehiclesData.map((car, index) => (
          <CarCard
            key={index}
            img={car.img}
            title={car.title}
            similarTo={car.similarTo}
            type={car.type}
            noOfPeople={car.noOfPeople}
            noOfBags={car.noOfBags}
            dailyRate={car.dailyRate}
            totalCost={car.totalCost}
            cardPath={car.cardPath}
            selectPath={car.selectPath}
          />
        ))}
      </div>
    </div>
  );
}
