import React from 'react';
import { Link } from 'react-router-dom';
import './index.css';

export default function CarCard({
  key,
  img,
  title,
  similarTo,
  type,
  noOfPeople,
  noOfBags,
  dailyRate,
  totalCost,
  cardPath,
  selectPath,
}) {
  return (
    <Link to={cardPath} key={key} className='carCard_wrapper'>
      <div className='carCard_hero_img'>
        <img src={`/assets/imgs/cars/${img}.png`} alt='car_1' />
      </div>
      <div className='carCard_body'>
        <div className='carCard_title'>{title}</div>
        <div className='car_details'>
          <div className='car_similar_to'>{similarTo}</div>
          <div className='price_details'>
            Features & Price Details
            <svg
              width='8'
              viewBox='0 0 8 14'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M-8.74228e-08 12L5 7L-5.24537e-07 2L0.999999 -4.37114e-08L8 7L1 14L-8.74228e-08 12Z'
                fill='#4FC8E9'
              />
            </svg>
          </div>
        </div>
        <div className='car_tags'>
          <span>
            <img src='/assets/imgs/automatic.png' alt='' />
            {type}
          </span>
          <span>
            <img src='/assets/imgs/people.png' alt='' />
            {noOfPeople} People
          </span>
          <span>
            <img src='/assets/imgs/bag.png' alt='' />
            {noOfBags} Bags
          </span>
        </div>

        <div className='car_costs'>
          <div className='daily_rate'>Daily Rate: CAD {dailyRate}.00</div>
          <div className='total_cost'>Total Cost: CAD {totalCost}.00</div>
        </div>

        <div className='car_select_btn'>
          <Link to={selectPath} type='submit'>
            Select
          </Link>
        </div>
      </div>
    </Link>
  );
}
