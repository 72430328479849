import React from 'react';
import { Link } from 'react-router-dom';
import { Nav, Navbar } from 'react-bootstrap';
import './index.css';

export default function NavBar() {
  return (
    <Navbar
      collapseOnSelect
      expand='lg'
      //   bg='dark'
      variant='dark'
      className='nav_bar'
    >
      {/* <Container> */}
      <Navbar.Brand className='logo'>
        <img src='/assets/imgs/logo.png' alt='logo' />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls='responsive-navbar-nav' />
      <Navbar.Collapse id='responsive-navbar-nav'>
        <Nav className='me-auto'></Nav>
        <Nav className='nav_buttons'>
          <Link to='/'>Featured Cars</Link>
          <Link to='/'>About Us</Link>
          <Link to='/'>Contact Us</Link>
          <Link to='/' className='nav_buttons_search'>
            <svg width='14' viewBox='0 0 22 22' fill='none'>
              <path
                d='M10.0833 17.4167C14.1334 17.4167 17.4167 14.1334 17.4167 10.0833C17.4167 6.03325 14.1334 2.75 10.0833 2.75C6.03325 2.75 2.75 6.03325 2.75 10.0833C2.75 14.1334 6.03325 17.4167 10.0833 17.4167Z'
                stroke='#4FC8E9'
                stroke-width='1.83333'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
              <path
                d='M19.2497 19.25L15.2622 15.2625'
                stroke='#4FC8E9'
                stroke-width='1.83333'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
            </svg>
          </Link>
        </Nav>
      </Navbar.Collapse>
      {/* </Container> */}
    </Navbar>
  );
}
