import React, { useState } from 'react';
import CarCard from '../../CardsWrapper/CarCard';
import Pagination from '@mui/material/Pagination';
import usePagination from '../../../hooks/usePagination';
import carDummyData from '../../../fixtures/carsDummyData.json';
import './index.css';

export default function ChooseVehicle() {
  let [page, setPage] = useState(1);
  const PER_PAGE = 6;

  const count = Math.ceil(carDummyData.length / PER_PAGE);
  const _DATA = usePagination(carDummyData, PER_PAGE);
  console.log(_DATA);

  const handlePageChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  return (
    <div className='featured_vehicles'>
      <div className='heading'>Choose A Vehicle Class</div>
      <div className='featured_card_wrapper'>
        {_DATA.currentData().map((car, index) => (
          <CarCard
            key={index}
            img={car.img}
            title={car.title}
            similarTo={car.similarTo}
            type={car.type}
            noOfPeople={car.noOfPeople}
            noOfBags={car.noOfBags}
            dailyRate={car.dailyRate}
            totalCost={car.totalCost}
            cardPath={car.cardPath}
            selectPath={car.selectPath}
          />
        ))}
      </div>
      <Pagination
        className='chooseVehicle_pagination'
        // variant='outlined'
        shape='circular'
        count={count}
        page={page}
        onChange={handlePageChange}
      />
    </div>
  );
}
