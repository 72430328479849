import React from 'react';
import Card from '../../Components/CardsWrapper/Card';
import Footer from '../../Components/Footer';
import Navbar from '../../Components/Navbar';
import TimingsCard from '../../Components/TimingsCard';
import './index.css';

export default function HomePage() {
  return (
    <div className='homePage'>
      <Navbar />
      <main>
        <img src='/assets/imgs/hero.png' alt='hero img' />
        <div className='img_wrapper'></div>
        <div className='hero_text'>
          Quality Cars with Unlimited <br /> Miles
        </div>
      </main>
      <TimingsCard />
      <div className='card_wrapper'>
        <div className='card_container'>
          <Card
            img='car'
            heading='4WD'
            body='The only guarenteed 4 wheel drive vehicles at the reno-tahoe
        international airport and truckee tahoe airport'
          />
          <Card
            img='plane'
            heading='Curbside'
            body='The only curbside delivery and pickup services at the reno-tahoe international 
        airport and truckee tahoe airport. Now servicingprivate jet customers flying into Atlantic Aviation!'
          />
          <Card
            img='diamond'
            heading='Luxury'
            body='From heated seats to navigation, leather and ski racks it all comes standard and best of 
        all includes free of charge!'
          />
        </div>
      </div>
      <Footer />
    </div>
  );
}
