import React, { Suspense } from 'react';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import HomePage from './pages/HomePage';
import FeaturedPage from './pages/FeaturedPage';

export default function App() {
  return (
    <BrowserRouter>
      {/* <Suspense fallback={<div className=''>Loading...</div>}> */}
      <Routes>
        <Route exact path='/' element={<HomePage />} />
        <Route path='/search' element={<FeaturedPage />} />
      </Routes>
      {/* </Suspense> */}
    </BrowserRouter>
  );
}
